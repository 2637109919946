import { darken } from '@mui/material/styles';

export const synColors = {
  FULL_BLACK: '#000',
  BLACK: '#181818',
  OFF_BLACK: '#2D2D2D',
  BLACK_PAPER: '#333333',
  BLACK_PAPER_ELEVATED: '#424242',
  OFF_WHITE: '#f5f5f5',
  LIGHT_BLUE: '#86cec2',
  BLUE: '#3D82D1',
  BRIGHT_GREEN: darken('#25CF2B', 0.1),
  EARTH_GREEN: '#495749',
  GREEN: '#006351',
  WHITE: '#FFF',
  YELLOW: '#FFC727',
};
