import {
  EntityAboutCard,
  EntityDependsOnComponentsCard,
  EntityDependsOnResourcesCard,
  EntityLayout,
  EntityLinksCard,
  EntityOrphanWarning,
  EntityProcessingErrorsPanel,
  EntitySwitch,
  hasCatalogProcessingErrors,
  isOrphan,
} from '@backstage/plugin-catalog';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
import React from 'react';

import {
  EntityAdrContent,
  isAdrAvailable,
} from '@backstage-community/plugin-adr';
import {
  EntityConsumedApisCard,
  EntityProvidedApisCard,
} from '@backstage/plugin-api-docs';
import {
  EntityTechdocsContent,
  isTechDocsAvailable,
} from '@backstage/plugin-techdocs';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { CoderWorkspacesCard } from '@coder/backstage-plugin-coder';
import {
  EntityGitlabContent,
  EntityGitlabReleasesCard,
  isGitlabAvailable,
} from '@immobiliarelabs/backstage-plugin-gitlab';
import { default as Grid } from '@mui/material/Grid2';
import { darken, styled } from '@mui/material/styles';
import { CoderWorkspaceCardHeader } from '../../coder';
import {
  isApiAvailable,
  isCoderAvailable,
  isDependenciesAvailable,
  isLinksAvailable,
} from '../util';

export const FixedHeightGrid = styled(Grid)({
  '& > div': {
    height: '100%',
  },
});

export const ScrollingFixedHeightGrid = styled(FixedHeightGrid)({
  '& > div': {
    height: '100%',
    overflow: 'auto',
    maxHeight: '40rem',
  },
});

const techdocsContent = (
  <EntityTechdocsContent>
    <TechDocsAddons>
      <ReportIssue />
    </TechDocsAddons>
  </EntityTechdocsContent>
);

export const entityWarningContent = (
  <>
    <EntitySwitch>
      <EntitySwitch.Case if={isOrphan}>
        <Grid>
          <EntityOrphanWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasCatalogProcessingErrors}>
        <Grid>
          <EntityProcessingErrorsPanel />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </>
);

export const commonOverviewContent = (
  <Grid container spacing={3} size={12} alignItems="stretch">
    {entityWarningContent}

    <Grid size={6}>
      <EntitySwitch>
        <EntitySwitch.Case if={isLinksAvailable}>
          <Grid
            container
            gap={3}
            size={12}
            justifyContent="space-between"
            direction="column"
            height="100%"
          >
            <EntityAboutCard variant="flex" />

            <EntityLinksCard variant="flex" />
          </Grid>
        </EntitySwitch.Case>

        <EntitySwitch.Case>
          <EntityAboutCard variant="fullHeight" />
        </EntitySwitch.Case>
      </EntitySwitch>
    </Grid>

    <Grid size={6}>
      <EntityCatalogGraphCard variant="fullHeight" height={400} />
    </Grid>

    <EntitySwitch>
      <EntitySwitch.Case if={isGitlabAvailable}>
        <Grid
          size={6}
          sx={theme => ({
            // Force Releases card to take proper height, and reasonable styling
            '& > div:nth-of-type(1)': {
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              margin: 0,
              '& div:nth-of-type(2)': {
                '& div:first-child': {
                  marginBottom: '4px',
                },
                '& div:not(:first-child)': {
                  paddingTop: '8px',
                  backgroundColor: darken(theme.palette.background.paper, 0.05),
                  border: `1px solid ${darken(
                    theme.palette.background.paper,
                    0.2,
                  )}`,
                  marginX: '4px',
                  marginY: '2px',
                  borderRadius: '8px',
                },
              },
            },
          })}
        >
          <EntityGitlabReleasesCard variant="fullHeight" />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={isCoderAvailable}>
        <FixedHeightGrid size={6}>
          <CoderWorkspacesCard
            readEntityData
            headerContent={<CoderWorkspaceCardHeader />}
          />
        </FixedHeightGrid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </Grid>
);

export const commonRoutes = [
  <EntityLayout.Route if={isTechDocsAvailable} path="/docs" title="Docs">
    {techdocsContent}
  </EntityLayout.Route>,

  <EntityLayout.Route if={isGitlabAvailable} path="/gitlab" title="Gitlab">
    <EntityGitlabContent />
  </EntityLayout.Route>,

  <EntityLayout.Route path="/api" title="API" if={isApiAvailable}>
    <Grid container spacing={3} size={12} alignItems="stretch">
      <Grid>
        <EntityProvidedApisCard />
      </Grid>
      <Grid>
        <EntityConsumedApisCard />
      </Grid>
    </Grid>
  </EntityLayout.Route>,

  <EntityLayout.Route
    if={isDependenciesAvailable}
    path="/dependencies"
    title="Dependencies"
  >
    <Grid container spacing={3} size={12} alignItems="stretch">
      <Grid>
        <EntityDependsOnComponentsCard variant="gridItem" />
      </Grid>
      <Grid>
        <EntityDependsOnResourcesCard variant="gridItem" />
      </Grid>
    </Grid>
  </EntityLayout.Route>,

  <EntityLayout.Route if={isAdrAvailable} path="/adrs" title="ADRs">
    <EntityAdrContent />
  </EntityLayout.Route>,
];
