import { genPageTheme, shapes, UnifiedThemeOptions } from '@backstage/theme';
import { lighten } from '@mui/material/styles';
import { contrastColor } from 'contrast-color';
import { synColors } from './synColors';

const { BLACK, OFF_WHITE, LIGHT_BLUE } = synColors;

type StyleObject = Record<string, any>;

interface ComponentStyles {
  styleOverrides: StyleObject;
}

export const generateComponentStyles = (
  styles: StyleObject,
): ComponentStyles => ({
  styleOverrides: { ...styles },
});

const themeColourTypes: { [key in keyof Partial<typeof synColors>]: string[] } =
  {
    BLACK: ['home', 'other', 'unknown'],
    BLUE: ['backend', 'benchmark', 'script', 'utility', 'website', 'workspace'],
    GREEN: [
      'container-image',
      'git',
      'gitops',
      'iac',
      'pattern',
      'policy',
      'terraform',
      'terraform-module',
      'terraform-provider',
    ],
    BRIGHT_GREEN: ['service'],
    EARTH_GREEN: ['team'],
  };

export const colouredPageThemes = Object.entries(themeColourTypes).reduce(
  (acc, [colour, pages]) => {
    pages.forEach(page => {
      acc[page] = genPageTheme({
        colors: [synColors[colour as keyof typeof synColors]],
        shape: shapes.wave,
        options: {
          fontColor: contrastColor({
            bgColor: synColors[colour as keyof typeof synColors],
          }),
        },
      });
    });
    return acc;
  },
  {} as Record<string, any>,
);

export const commonPalette = {
  error: {
    main: '#8c4351',
  },
  warning: {
    main: '#8f5e15',
  },
  info: {
    main: '#34548a',
  },
  success: {
    main: '#485e30',
  },
  banner: {
    info: '#34548a',
    error: '#8c4351',
    link: '#565a6e',
    text: '#000000',
  },
  errorBackground: '#8c4351',
  warningBackground: '#8f5e15',
  navigation: {
    background: BLACK,
    indicator: LIGHT_BLUE,
    color: OFF_WHITE,
    selectedColor: BLACK,
    navItem: {
      hoverBackground: lighten(BLACK, 0.1),
      linkHover: BLACK,
    },
  },
};

export const commonComponentOverrides: UnifiedThemeOptions['components'] = {
  BackstagePage: generateComponentStyles({
    // Overwrite the height of the BackstagePage because we combine it with our TopBar and want to combine the scrolling
    root: {
      height: '100%',
      overflowY: 'visible',
    },
  }),
  BackstageHeader: generateComponentStyles({
    header: {
      boxShadow: 'unset',
      padding: '2rem 4rem',
    },
    subtitle: {
      display: 'none',
    },
  }),
  BackstageItemCardHeader: generateComponentStyles({
    root: {
      color: `${contrastColor({
        bgColor: '#FFC727',
      })} !important`,
      background:
        'linear-gradient(85deg, #FFC727 0.13%, #DFEB39 98.22%) !important',
      boxShadow:
        '0px 0.88px 0.88px 0px rgba(0, 0, 0, 0.14), 0px 1.759px 0.88px 0px rgba(0, 0, 0, 0.12), 0px 0.88px 2.639px 0px rgba(0, 0, 0, 0.20)',
    },
  }),
  BackstageSidebarItem: generateComponentStyles({
    selected: {
      color: `${OFF_WHITE} !important`,
    },
  }),
  MuiTableHead: generateComponentStyles({
    root: {
      '& > tr > th': {
        padding: '16px',
        fontWeight: 'large',
        textTransform: 'capitalize',
      },
    },
  }),
  // @ts-ignore This component is not typed but is exported by backstage
  BackstageSelect: generateComponentStyles({
    formLabel: {
      textTransform: 'uppercase',
    },
  }),
  // @ts-ignore This component is not typed but is exported by backstage
  BackstageAutocomplete: generateComponentStyles({
    label: {
      textTransform: 'uppercase !important',
    },
  }),
  BackstageAutocompleteBase: generateComponentStyles({
    inputRoot: {
      paddingBlock: '8px !important',
    },
  }),
  MuiPaper: generateComponentStyles({
    elevation1: {
      boxShadow: 'none !important', // Figma design requires no shadow
    },
  }),
};
