import { SearchModal, useSearchModal } from '@backstage/plugin-search';
import {
  SearchBarBase,
  SearchBarBaseProps,
} from '@backstage/plugin-search-react';
import { synColors } from '@internal/backstage-plugin-synechron-theme-react';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';

const SearchBar = styled(SearchBarBase)(({ theme }) => {
  const { mode } = theme.palette;
  return {
    width: '40% !important',
    maxWidth: '40rem',
    margin: '0 !important',
    fontSize: '1.5em',
    borderRadius: '10rem',
    transition: 'visibility 0.2s, opacity 0.2s linear',
    '& .MuiOutlinedInput-root': {
      border: `1px solid ${synColors.BLACK}`,
    },
    '& .MuiInputBase-root': {
      borderRadius: '2rem',
    },
    ...(mode === 'dark' && {
      backgroundColor: synColors.OFF_WHITE,
      color: synColors.BLACK,
      '& button, span, input': {
        color: synColors.BLACK,
      },
    }),
  };
});

export const TopSearchBar = (
  props: Partial<Omit<SearchBarBaseProps, 'onChange' | 'onSubmit'>>,
) => {
  const { state, toggleModal } = useSearchModal();
  const smallScreen = useMediaQuery(useTheme().breakpoints.down('sm'));

  const isModalVisible = state.hidden === false && state.open;

  return (
    <>
      {smallScreen ? (
        <IconButton onClick={() => toggleModal()}>
          <SearchIcon />
        </IconButton>
      ) : (
        <SearchBar
          value=""
          onClick={() => toggleModal()}
          onChange={() => {}}
          clearButton={false}
          sx={{
            visibility: isModalVisible ? 'hidden' : 'visible',
            opacity: isModalVisible ? 0 : 1,
          }}
          InputProps={{
            placeholder: 'Search',
          }}
          {...props}
        />
      )}

      <SearchModal {...state} toggleModal={toggleModal} />
    </>
  );
};

/*
  The code below provides a search bar which handles search directly, but does not provide autocomplete suggestions.
*/

// const useNavigateToQuery = () => {
//   const navigate = useNavigate();
//   return useCallback(
//     ({ query }: { query: string }): void => {
//       const queryString = qs.stringify({ query }, { addQueryPrefix: true });

//       navigate(`search${queryString}`);
//     },
//     [navigate],
//   );
// };

// export const TopSearchBar = (
//   props: Partial<Omit<SearchBarBaseProps, 'onChange' | 'onSubmit'>>,
// ) => {
//   const [query, setQuery] = useState('');
//   const ref = useRef<HTMLInputElement | null>(null);

//   const handleSearch = useNavigateToQuery();

//   // This handler is called when "enter" is pressed
//   const handleSubmit = useCallback(() => {
//     // Using ref to get the current field value without waiting for a query debounce
//     handleSearch({ query: ref.current?.value ?? '' });
//   }, [handleSearch]);

//   return (
//     <SearchBar
//       value={query}
//       onSubmit={handleSubmit}
//       onChange={setQuery}
//       inputProps={{ ref }}
//       InputProps={{
//         placeholder: 'Search',
//         ...props.InputProps,
//         classes: {
//           ...props.InputProps?.classes,
//         },
//       }}
//       {...props}
//     />
//   );
// };
