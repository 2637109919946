import { gitlabAuthApiRef, SignInPageProps } from '@backstage/core-plugin-api';
import { synColors } from '@internal/backstage-plugin-synechron-theme-react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React from 'react';
import { SignInPage } from './SignInPage';

export const SynechronSignInPage = (props: SignInPageProps) => {
  return (
    <SignInPage
      provider={{
        id: 'gitlab-auth-provider',
        title: 'Gitlab',
        subheading: 'Enter through your Gitlab Account',
        message: (
          <Typography>
            You will have access based on the Gitlab account created via
            Synechron. If you need an account, raise a{' '}
            <Link
              href="https://synefirst.synechron.com/"
              sx={{
                color: synColors.BLUE,
                textDecoration: 'none',
              }}
            >
              request for Gitlab SaaS in SyneFirst
            </Link>
            .
          </Typography>
        ),
        apiRef: gitlabAuthApiRef,
      }}
      {...props}
    />
  );
};
