import { useApi } from '@backstage/core-plugin-api';
import { visitsApiRef } from '@backstage/plugin-home';
import useAsync from 'react-use/esm/useAsync';

export const useRecentVisits = () => {
  const visitsApi = useApi(visitsApiRef);

  const { loading, error, value } = useAsync(async () => {
    const visits = await visitsApi.list();

    return visits.map(v => v.entityRef).filter(Boolean) as string[];
  }, [visitsApi]);

  return { loading, error, visits: value };
};
