import { EntityLayout } from '@backstage/plugin-catalog';
import {
  commonOverviewContent,
  commonRoutes,
  ScrollingFixedHeightGrid,
} from './common';

import {
  EntityApiDefinitionCard,
  EntityConsumingComponentsCard,
  EntityProvidingComponentsCard,
} from '@backstage/plugin-api-docs';
import React from 'react';

import { default as Grid } from '@mui/material/Grid2';

export const apiPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} size={12} alignItems="stretch">
        {commonOverviewContent}
        <ScrollingFixedHeightGrid size={6}>
          <EntityProvidingComponentsCard />
        </ScrollingFixedHeightGrid>
        <ScrollingFixedHeightGrid size={6}>
          <EntityConsumingComponentsCard />
        </ScrollingFixedHeightGrid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/definition" title="Definition">
      <Grid>
        <Grid>
          <EntityApiDefinitionCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    {...commonRoutes}
  </EntityLayout>
);
