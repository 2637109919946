import { EntityHasSystemsCard, EntityLayout } from '@backstage/plugin-catalog';
import { default as Grid } from '@mui/material/Grid2';
import React from 'react';
import { commonOverviewContent, commonRoutes } from './common';

export const domainPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} size={12} alignItems="stretch">
        {commonOverviewContent}
        <EntityHasSystemsCard variant="gridItem" />
      </Grid>
    </EntityLayout.Route>
    {...commonRoutes}
  </EntityLayout>
);
