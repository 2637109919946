import IconClose from '@mui/icons-material/Close';
import IconPin from '@mui/icons-material/PushPin';
import Divider from '@mui/material/Divider';
import { default as Grid } from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import React, { useContext } from 'react';
import { RightbarContext } from '../../sidebar';

const PinButton = () => {
  const { isPinned, handleTogglePinned } = useContext(RightbarContext);

  return (
    <IconButton size="small" onClick={() => handleTogglePinned()}>
      <IconPin
        sx={{
          mt: '2px',
          pt: '1px',
          transform: `rotate(${isPinned ? '0deg' : '45deg'})`,
        }}
      />
    </IconButton>
  );
};

export const SidebarHeaderActions = () => {
  const { handleDrawerClose } = useContext(RightbarContext);

  return (
    <Grid container direction="column" gap={0}>
      <Grid container direction="row" justifyContent="flex-end" p="0.25rem">
        <PinButton />
        <IconButton onClick={() => handleDrawerClose(true)} size="small">
          <IconClose />
        </IconButton>
      </Grid>
      <Divider />
    </Grid>
  );
};
