import { EntityLayout } from '@backstage/plugin-catalog';
import React from 'react';
import { commonOverviewContent, commonRoutes } from './common';

export const componentPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {commonOverviewContent}
    </EntityLayout.Route>
    {...commonRoutes}
  </EntityLayout>
);
