import { SignInProviderConfig, UserIdentity } from '@backstage/core-components';
import {
  BackstageIdentityResponse,
  SignInPageProps,
  useApi,
} from '@backstage/core-plugin-api';
import { useMountEffect } from '@react-hookz/web';
import { ReactNode, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export type SingleSignInPageProps = SignInPageProps & {
  provider: Omit<SignInProviderConfig, 'message'> & {
    subheading?: string;
    message: string | ReactNode;
  };
  auto?: boolean;
};

export type SignInPageOutput = {
  showLoginPage: boolean;
  login: (opts: {
    checkExisting?: boolean;
    showPopup?: boolean;
  }) => Promise<void>;
  error?: Error;
};

export const useSignInPage = ({
  provider,
  auto,
  onSignInSuccess,
}: SingleSignInPageProps) => {
  const authApi = useApi(provider.apiRef);
  const [error, setError] = useState<Error>();

  // The SignIn component takes some time to decide whether the user is logged-in or not.
  // showLoginPage is used to prevent a glitch-like experience where the sign-in page is
  // displayed for a split second when the user is already logged-in.
  const [showLoginPage, setShowLoginPage] = useState<boolean>(false);

  // User was redirected back to sign in page with error from auth redirect flow
  const [searchParams, setSearchParams] = useSearchParams();
  const errorParam = searchParams.get('error');

  type LoginOpts = { checkExisting?: boolean; showPopup?: boolean };
  const login = async ({ checkExisting, showPopup }: LoginOpts) => {
    try {
      let identityResponse: BackstageIdentityResponse | undefined;
      if (checkExisting) {
        // Do an initial check if any logged-in session exists
        identityResponse = await authApi.getBackstageIdentity({
          optional: true,
        });
      }

      // If no session exists, show the sign-in page
      if (!identityResponse && (showPopup || auto) && !errorParam) {
        // Unless auto is set to true, this step should not happen.
        // When user intentionally clicks the Sign In button, autoShowPopup is set to true
        setShowLoginPage(true);
        identityResponse = await authApi.getBackstageIdentity({
          instantPopup: true,
        });
        if (!identityResponse) {
          throw new Error(
            `The ${provider.title} provider is not configured to support sign-in`,
          );
        }
      }

      if (!identityResponse) {
        setShowLoginPage(true);
        return;
      }

      const profile = await authApi.getProfile();
      onSignInSuccess(
        UserIdentity.create({
          identity: identityResponse.identity,
          authApi,
          profile,
        }),
      );
    } catch (err: any) {
      // User closed the sign-in modal
      setError(err);
      setShowLoginPage(true);
    }
  };

  useMountEffect(() => {
    if (errorParam) {
      setError(new Error(errorParam));
      // Clear the error from the URL
      setSearchParams({});
    }
    login({ checkExisting: true });
  });

  return { showLoginPage, login, error };
};
