import { EntityLayout } from '@backstage/plugin-catalog';
import {
  EntityOwnershipCard,
  EntityUserProfileCard,
} from '@backstage/plugin-org';
import { default as Grid } from '@mui/material/Grid2';
import React from 'react';
import { entityWarningContent } from './common';

export const userPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch" size={12}>
        {entityWarningContent}
        <Grid size={6}>
          <EntityUserProfileCard variant="gridItem" />
        </Grid>
        <Grid size={6}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);
