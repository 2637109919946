import ClickAwayListener from '@mui/material/ClickAwayListener';
import { default as Grid } from '@mui/material/Grid2';
import React, {
  PropsWithChildren,
  useContext,
  useLayoutEffect,
  useState,
} from 'react';
import { RightbarContext } from '../sidebar';

const calculateMargin = (scrollTop = 0) => {
  return scrollTop > 112 ? 0 : 112 - scrollTop;
};

const SideMenuPaper = (
  props: PropsWithChildren<{
    heightAdjustment: number;
    handleDrawerClose: () => void;
  }>,
) => (
  <ClickAwayListener onClickAway={() => props.handleDrawerClose()}>
    <Grid
      {...props}
      container
      direction="column"
      wrap="nowrap"
      overflow="auto"
      gap={2}
      sx={theme => ({
        pointerEvents: 'auto',
        height: '100%',
        background: theme.palette.background.paper,
        marginTop: `${props.heightAdjustment}px`,
        marginLeft: '10px',
        boxShadow: '-10px 0px 10px -10px rgba(0,0,0,0.2)',
      })}
    />
  </ClickAwayListener>
);

export const PositionAdjustedMenu = (props: PropsWithChildren<{}>) => {
  const scrollContainer = document.getElementById('syne-scroll-container');
  const [marginTop, setMarginTop] = useState(calculateMargin());
  const { handleDrawerClose } = useContext(RightbarContext);

  useLayoutEffect(() => {
    if (scrollContainer && !scrollContainer.getAttribute('onscroll')) {
      scrollContainer.onscroll = () => {
        setMarginTop(calculateMargin(scrollContainer.scrollTop));
      };
    }
  }, [scrollContainer]);

  return (
    <SideMenuPaper
      heightAdjustment={marginTop}
      handleDrawerClose={handleDrawerClose}
    >
      {props.children}
    </SideMenuPaper>
  );
};
