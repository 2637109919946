import { EntitySwitch, isKind } from '@backstage/plugin-catalog';
import React from 'react';

import { apiPage } from './entityPages/api';
import { componentPage } from './entityPages/component';
import { domainPage } from './entityPages/domain';
import { groupPage } from './entityPages/group';
import { systemPage } from './entityPages/system';
import { userPage } from './entityPages/user';

export const entityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isKind('component')} children={componentPage} />
    <EntitySwitch.Case if={isKind('api')} children={apiPage} />
    <EntitySwitch.Case if={isKind('group')} children={groupPage} />
    <EntitySwitch.Case if={isKind('user')} children={userPage} />
    <EntitySwitch.Case if={isKind('system')} children={systemPage} />
    <EntitySwitch.Case if={isKind('domain')} children={domainPage} />

    <EntitySwitch.Case>{componentPage}</EntitySwitch.Case>
  </EntitySwitch>
);
