import { useSidebarOpenState } from '@backstage/core-components';
import { synColors } from '@internal/backstage-plugin-synechron-theme-react';
import Link from '@mui/material/Link';
import { lighten, styled } from '@mui/material/styles';
import React from 'react';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';

const LogoContainer = styled('div')({
  width: '100%',
  padding: '1rem 0',
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  textAlign: 'center',
});

const LogoLink = styled(Link)({
  width: '100%',
});

const LogoHr = styled('hr')({
  width: '100%',
  border: 'none',
  marginTop: 0,
  height: '1px',
  background: lighten(synColors.BLACK, 0.2),
});

export const SidebarLogo = () => {
  const { isOpen } = useSidebarOpenState();

  return (
    <>
      <LogoContainer>
        <LogoLink href="/homepage" underline="none" aria-label="Home">
          {isOpen ? <LogoFull /> : <LogoIcon />}
        </LogoLink>
      </LogoContainer>
      <LogoHr />
    </>
  );
};
