import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const synechronAppsPlugin = createPlugin({
  id: 'synechron-apps',
  routes: {
    root: rootRouteRef,
  },
});

export const SynechronAppsPage = synechronAppsPlugin.provide(
  createRoutableExtension({
    name: 'SynechronAppsPage',
    component: () => import('./components/AppsPage').then(m => m.AppsPage),
    mountPoint: rootRouteRef,
  }),
);
