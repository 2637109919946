import { LinkButton } from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';
import { catalogPlugin } from '@backstage/plugin-catalog';
import { searchPlugin } from '@backstage/plugin-search';
import AddIcon from '@mui/icons-material/Add';
import IconBookmarks from '@mui/icons-material/Bookmarks';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { styled, useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { RightbarContext } from '../sidebar';
import { TopSearchBar } from './TopSearchBar';

const TopBarContainer = styled(Box)({
  position: 'sticky',
  padding: '2rem',
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
  height: '7rem',
});

const searchRouteRef = searchPlugin.routes.root;
const catalogRouteRef = catalogPlugin.routes.catalogIndex;

const CreateButton = () => {
  const currentRoute = useLocation().pathname;
  const midScreen = useMediaQuery(useTheme().breakpoints.down('md'));

  if (midScreen)
    return (
      <LinkButton to="create" color="primary" variant="contained" noWrap>
        <AddIcon fontSize="small" />
      </LinkButton>
    );

  return currentRoute.startsWith('/create') ? null : (
    <LinkButton to="create" color="primary" variant="contained" noWrap>
      Create Using Template
    </LinkButton>
  );
};

const CatalogButton = () => {
  const currentRoute = useLocation().pathname;
  const catalogRoute = useRouteRef(catalogRouteRef)();

  if (currentRoute === catalogRoute) return null;

  return (
    <LinkButton to={catalogRoute} color="primary" variant="text">
      Catalog
    </LinkButton>
  );
};

const FavouritesButton = () => {
  const { handleToggleDrawer } = useContext(RightbarContext);

  return (
    <>
      <Divider orientation="vertical" flexItem sx={{ borderWidth: '1.5px' }} />
      <Tooltip
        title="Recents & Favourites"
        placement="bottom"
        arrow
        slotProps={{
          popper: {
            modifiers: [{ name: 'offset', options: { offset: [0, -5] } }],
          },
        }}
      >
        <IconButton onClick={handleToggleDrawer} id="syne-favourites-button">
          <IconBookmarks fontSize="small" />
        </IconButton>
      </Tooltip>
    </>
  );
};

export const TopBar = () => {
  const currentRoute = useLocation().pathname;
  const searchRoute = useRouteRef(searchRouteRef)();

  const shouldShowSearch = !['/homepage', '/', searchRoute].includes(
    currentRoute,
  );

  return (
    <TopBarContainer>
      {shouldShowSearch && <TopSearchBar />}
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '1rem',
          minHeight: '2.75rem',
        }}
      >
        <CatalogButton key="catalog" />
        <CreateButton key="create" />
        <FavouritesButton key="favourites" />
      </Box>
    </TopBarContainer>
  );
};
