import { Content, InfoCard, Page, Progress } from '@backstage/core-components';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { default as Grid } from '@mui/material/Grid2';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { PropsWithChildren } from 'react';
import LogoFull from '../assets/LogoFull';
import SignInBackground from '../assets/SignInBackground.png';
import {
  SignInPageOutput,
  SingleSignInPageProps,
  useSignInPage,
} from '../hooks/useSignInPage';

const BackgroundContent = styled(Content)({
  backgroundImage: `url(${SignInBackground})`,
  backgroundSize: 'cover',
  height: '100% !important',
  padding: '0 !important',
});

const StyledInfoCard = styled(InfoCard)({
  marginTop: '2rem',
  maxWidth: '45rem',
  boxShadow: 'none !important',
  width: '100%',
  padding: '1.5rem',
  '& hr': {
    display: 'none',
  },
});

const ProviderInfoCard = ({
  provider: { title, subheading, message },
  login,
  error,
}: PropsWithChildren<
  Pick<SingleSignInPageProps, 'provider'> & SignInPageOutput
>) => (
  <StyledInfoCard
    variant="flex"
    actions={
      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          login({ showPopup: true });
        }}
      >
        Sign In
      </Button>
    }
  >
    <Box gap={2} display="flex" flexDirection="column">
      <Typography variant="h4">{title}</Typography>
      <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
        {subheading}
      </Typography>
      {typeof message === 'string' ? (
        <Typography variant="body1">{message}</Typography>
      ) : (
        message
      )}
      {error && <Alert severity="error">{error.message}</Alert>}
    </Box>
  </StyledInfoCard>
);

const LogoWithText = () => (
  <Grid
    container
    sx={{
      width: '100%',
      maxWidth: '37.25rem',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      gap: 2,
      wrap: 'nowrap',
    }}
  >
    <Typography
      variant="h3"
      sx={{
        fontWeight: 'normal',
        textTransform: 'uppercase',
        whiteSpace: 'nowrap',
      }}
    >
      Welcome to
    </Typography>
    <LogoFull />
  </Grid>
);

const SignInLayout = ({ children }: PropsWithChildren<{}>) => (
  <Grid
    container
    size={12}
    sx={theme => ({
      justifyContent: 'end',
      height: '100%',
      [theme.breakpoints.down('lg')]: {
        justifyContent: 'center',
        alignItems: 'center',
      },
    })}
  >
    <Grid
      container
      sx={theme => ({
        width: '50%',
        boxShadow: '-11px 1px 29.6px 0px #00000040',
        backgroundColor: 'background.default',
        borderRadius: '1.5rem 0 0 1.5rem',
        padding: '2rem',
        minWidth: 'fit-content',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 1,
        [theme.breakpoints.down('lg')]: {
          margin: '2rem',
          borderRadius: '1rem',
          width: 'unset',
        },
      })}
    >
      {children}
    </Grid>
  </Grid>
);

export const SignInPage = (props: SingleSignInPageProps) => {
  const signIn = useSignInPage(props);

  return signIn.showLoginPage ? (
    <Box sx={{ overflowY: 'auto', height: '100vh' }}>
      <Page themeId="home">
        <BackgroundContent>
          <SignInLayout>
            <LogoWithText />
            <ProviderInfoCard {...props} {...signIn} />
          </SignInLayout>
        </BackgroundContent>
      </Page>
    </Box>
  ) : (
    <Progress />
  );
};
