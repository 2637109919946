import { Progress } from '@backstage/core-components';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { default as Grid } from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import React, { PropsWithChildren, useContext } from 'react';
import { RightbarContext } from '../../sidebar';
import { FavouriteEntityListItem } from './FavouriteEntityListItem';
import { NoItemsWarning } from './NoItemsWarning';

const MenuSection = (props: PropsWithChildren<{ title: string }>) => {
  return (
    <Grid
      container
      direction="column"
      gap={0}
      wrap="nowrap"
      sx={{
        paddingBottom: '1rem 2rem',
        minWidth: '25rem',
      }}
    >
      <Typography variant="h6" sx={{ alignSelf: 'center' }}>
        {props.title}
      </Typography>
      {props.children}
    </Grid>
  );
};

export type MenuSectionsData = [
  string,
  {
    refs: string[];
    emptyProps: { itemType: string; showBtn?: boolean };
    loading: boolean;
  },
][];

export const MenuSections = ({ groups }: { groups: MenuSectionsData }) => {
  const { handleDrawerClose } = useContext(RightbarContext);

  const renderSectionContent = ({
    refs,
    emptyProps,
    loading,
  }: MenuSectionsData[0][1]) => {
    if (loading)
      return (
        <Box sx={{ p: '1rem' }}>
          <Progress />
        </Box>
      );

    if (refs.length === 0) {
      return (
        <NoItemsWarning
          itemType={emptyProps.itemType}
          showBtn={emptyProps.showBtn}
        />
      );
    }

    return refs.map((text: string) => (
      <FavouriteEntityListItem
        entityRef={text}
        handleClose={() => handleDrawerClose()}
      />
    ));
  };

  return (
    <>
      {groups.map(([sectionName, { refs, emptyProps, loading }], i) => (
        <>
          {i !== 0 && <Divider />}
          <MenuSection title={sectionName}>
            {renderSectionContent({ refs, emptyProps, loading })}
          </MenuSection>
        </>
      ))}
    </>
  );
};
