import Button from '@mui/material/Button';
import { default as Grid } from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import React from 'react';

export const NoItemsWarning = ({
  itemType,
  showBtn,
}: {
  itemType: string;
  showBtn?: boolean;
}) => (
  <Grid
    sx={{ p: '8px' }}
    container
    direction="column"
    spacing={1}
    alignItems="center"
  >
    <Typography variant="body1" fontWeight="bold">
      You don't have any {itemType} yet
    </Typography>
    {showBtn && (
      <Button href="/catalog" variant="outlined">
        Look for something to favorite
      </Button>
    )}
  </Grid>
);
