import { useStarredEntities } from '@backstage/plugin-catalog-react';
import Drawer from '@mui/material/Drawer';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import React, { useContext, useMemo } from 'react';
import { RightbarContext } from '../sidebar';
import { MenuSections, MenuSectionsData } from './components/MenuSections';
import { SidebarHeaderActions } from './components/SidebarHeaderActions';
import { useRecentVisits } from './hooks/useRecentVisits';
import { PositionAdjustedMenu } from './PositionAdjustedMenu';

const HiddenPaper = styled(Paper)({
  backgroundColor: 'transparent !important',
  boxShadow: 'none !important',
  pointerEvents: 'none',
  border: 'none !important',
});

const getFormattedGroups = (
  starredEntities: string[],
  visits: string[] | undefined,
  visitsLoading: boolean,
) => ({
  'Starred Entities': {
    refs: [...starredEntities],
    emptyProps: {
      itemType: 'favourites',
      showBtn: true,
    },
    loading: false,
  },
  'Recent Entities': {
    refs: visits ?? [],
    emptyProps: {
      itemType: 'recent visits',
    },
    loading: visitsLoading,
  },
});

export const SidebarRight = () => {
  const { starredEntities } = useStarredEntities();
  const { isOpen, handleDrawerClose } = useContext(RightbarContext);
  const { visits, loading: visitsLoading } = useRecentVisits();

  const groups: MenuSectionsData = useMemo(
    () =>
      Object.entries(
        getFormattedGroups([...starredEntities], visits, visitsLoading),
      ),
    [starredEntities, visits, visitsLoading],
  );

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={isOpen}
      onClose={() => handleDrawerClose()}
      PaperProps={{
        component: HiddenPaper,
      }}
    >
      {isOpen && (
        <PositionAdjustedMenu>
          <SidebarHeaderActions />
          <MenuSections groups={groups} />
        </PositionAdjustedMenu>
      )}
    </Drawer>
  );
};
