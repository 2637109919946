import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreateComponentIcon from '@mui/icons-material/AddCircleOutline';
import AppsIcon from '@mui/icons-material/Apps';
import AssistantIcon from '@mui/icons-material/Assistant';
import DirectionsIcon from '@mui/icons-material/Directions';
import HomeIcon from '@mui/icons-material/Home';
import LibraryBooks from '@mui/icons-material/LibraryBooks';
import SchoolIcon from '@mui/icons-material/School';
import React, {
  createContext,
  createRef,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';

import {
  CatalogIcon,
  Sidebar,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarScrollWrapper,
  SidebarSpace,
} from '@backstage/core-components';
import { devToolsAdministerPermission } from '@backstage/plugin-devtools-common';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import BuildIcon from '@mui/icons-material/Build';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import { SidebarRight } from '../sidebar-right/SidebarRight';
import { TopBar } from '../topbar/TopBar';
import { SidebarLogo } from './Logo/SidebarLogo';
import { LocalStorage } from './hooks/util';

type SidebarContextType = {
  isOpen: boolean;
  isPinned: boolean;
  handleDrawerClose: (ignorePin?: boolean) => void;
  handleToggleDrawer: () => void;
  handleTogglePinned: () => void;
};

export const RightbarContext = createContext<SidebarContextType>({
  isOpen: false,
  isPinned: false,
  handleDrawerClose: () => {},
  handleToggleDrawer: () => {},
  handleTogglePinned: () => {},
});

const TopBarWithRightbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isPinned, setIsPinned] = useState(false);

  const handleDrawerClose = (ignorePin?: boolean) => {
    if (isPinned && !ignorePin) return undefined;
    return setIsOpen(false);
  };

  const handleToggleDrawer = () => setIsOpen(prev => !prev);
  const handleTogglePinned = () => setIsPinned(prev => !prev);

  return (
    <RightbarContext.Provider
      value={{
        isOpen,
        isPinned,
        handleDrawerClose,
        handleToggleDrawer,
        handleTogglePinned,
      }}
    >
      <TopBar />
      <SidebarRight />
    </RightbarContext.Provider>
  );
};

export const SynechronNavigation = ({ children }: PropsWithChildren<{}>) => {
  const [hasRendered, setHasRendered] = useState(false);
  const hasRemovedDefaultPinState =
    LocalStorage.getHasRemovedPinnedSettingsDefault();

  const scrollRef = createRef<HTMLDivElement>();

  useEffect(() => {
    /*
      Hacky: To avoid recreating the <SidebarPage> component, which defaults to a pinned sidebar,
      we set the pin state to false. This is done directly through LocalStorage because the
      useSidebarPinState context is not available at this point in the render.
    */
    if (!hasRemovedDefaultPinState) {
      LocalStorage.setSidebarPinState(false);
      LocalStorage.setHasRemovedPinnedSettingsDefault();
    }

    setHasRendered(true);
  }, [hasRemovedDefaultPinState]);

  return hasRendered ? (
    <SidebarPage>
      <Sidebar>
        <SidebarLogo />
        <SidebarGroup label="Home" icon={<HomeIcon />} to="/homepage">
          <SidebarItem icon={HomeIcon} to="/homepage" text="Home" />
        </SidebarGroup>
        <SidebarDivider />
        <SidebarGroup label="Menu" icon={<MenuIcon />}>
          <SidebarItem icon={CatalogIcon} to="components" text="Catalog" />
          <SidebarItem icon={AppsIcon} to="systems" text="Systems" />
          <SidebarItem to="ai-apps" text="AI Apps" icon={AssistantIcon} />
          <SidebarItem icon={LibraryBooks} to="docs" text="Docs" />
          <SidebarItem
            icon={CreateComponentIcon}
            to="create"
            text="Create..."
          />
          <SidebarDivider />
          <SidebarScrollWrapper>
            <SidebarItem
              icon={DirectionsIcon}
              to="tech-radar"
              text="Skills Radar"
            />
            <SidebarItem
              icon={SchoolIcon}
              to="docs/default/component/speed-engineering-guidelines"
              text="SPEED"
            />
            <RequirePermission
              permission={devToolsAdministerPermission}
              errorPage={<></>}
            >
              <SidebarItem
                icon={AccountBalanceIcon}
                to="infrawallet"
                text="FinOps"
              />
            </RequirePermission>
          </SidebarScrollWrapper>
        </SidebarGroup>
        <SidebarSpace />
        <SidebarDivider />
        <SidebarGroup
          label="Settings"
          icon={<UserSettingsSignInAvatar />}
          to="/settings"
        >
          <RequirePermission
            permission={devToolsAdministerPermission}
            errorPage={<></>}
          >
            <SidebarItem icon={BuildIcon} to="devtools" text="DevTools" />
          </RequirePermission>
          <SidebarSettings />
        </SidebarGroup>
      </Sidebar>
      <Box
        sx={{ overflowY: 'auto', height: '100vh' }}
        ref={scrollRef}
        id="syne-scroll-container"
      >
        <SearchContextProvider>
          <TopBarWithRightbar />
          {children}
        </SearchContextProvider>
      </Box>
    </SidebarPage>
  ) : null;
};
