import {
  RELATION_API_CONSUMED_BY,
  RELATION_API_PROVIDED_BY,
  RELATION_CONSUMES_API,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
  RELATION_PART_OF,
  RELATION_PROVIDES_API,
} from '@backstage/catalog-model';
import { EntityLayout } from '@backstage/plugin-catalog';
import {
  commonOverviewContent,
  commonRoutes,
  FixedHeightGrid,
  ScrollingFixedHeightGrid,
} from './common';

import { EntityHasApisCard } from '@backstage/plugin-api-docs';
import {
  EntityHasComponentsCard,
  EntityHasResourcesCard,
  EntitySwitch,
} from '@backstage/plugin-catalog';
import {
  Direction,
  EntityCatalogGraphCard,
} from '@backstage/plugin-catalog-graph';
import React from 'react';

import {
  EntityGrafanaAlertsCard,
  EntityGrafanaDashboardsCard,
  EntityOverviewDashboardViewer,
  isDashboardSelectorAvailable,
  isOverviewDashboardAvailable,
} from '@backstage-community/plugin-grafana';
import { EntityKubernetesContent } from '@backstage/plugin-kubernetes';
import { default as Grid } from '@mui/material/Grid2';

const grafanaContent = (
  <>
    <EntitySwitch>
      <EntitySwitch.Case
        if={entity => Boolean(isDashboardSelectorAvailable(entity))}
      >
        <FixedHeightGrid
          size={6}
          sx={{
            '*[class^="MuiPaper-root"]': {
              /* The plugin card does not have a height set, which causes grid misalignment */
              height: '100%',
              '*[class^="MuiTypography-root"] > p': {
                /* The plugin card nests a <p> inside <Typography> which causes inconsistent styling */
                font: 'unset',
              },
            },
          }}
        >
          <EntityGrafanaDashboardsCard />
        </FixedHeightGrid>
        <FixedHeightGrid
          size={6}
          sx={{
            '*[class^="MuiPaper-root"]': {
              /* The plugin card does not have a height set, which causes grid misalignment */
              height: '100%',
            },
          }}
        >
          <EntityGrafanaAlertsCard showState />
        </FixedHeightGrid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={isOverviewDashboardAvailable}>
        <Grid size={12}>
          <EntityOverviewDashboardViewer />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </>
);

export const systemPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} size={12} alignItems="stretch">
        {commonOverviewContent}
        {grafanaContent}
        <ScrollingFixedHeightGrid size={4}>
          <EntityHasComponentsCard variant="gridItem" />
        </ScrollingFixedHeightGrid>
        <ScrollingFixedHeightGrid size={4}>
          <EntityHasApisCard variant="gridItem" />
        </ScrollingFixedHeightGrid>
        <ScrollingFixedHeightGrid size={4}>
          <EntityHasResourcesCard variant="gridItem" />
        </ScrollingFixedHeightGrid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/diagram" title="Diagram">
      <EntityCatalogGraphCard
        variant="gridItem"
        direction={Direction.TOP_BOTTOM}
        title="System Diagram"
        height={700}
        relations={[
          RELATION_PART_OF,
          RELATION_HAS_PART,
          RELATION_API_CONSUMED_BY,
          RELATION_API_PROVIDED_BY,
          RELATION_CONSUMES_API,
          RELATION_PROVIDES_API,
          RELATION_DEPENDENCY_OF,
          RELATION_DEPENDS_ON,
        ]}
        unidirectional={false}
      />
    </EntityLayout.Route>

    <EntityLayout.Route path="/kubernetes" title="Kubernetes">
      <EntityKubernetesContent refreshIntervalMs={30000} />
    </EntityLayout.Route>

    {...commonRoutes}
  </EntityLayout>
);
