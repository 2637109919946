import { Content, Page } from '@backstage/core-components';
import {
  HomePageCompanyLogo,
  HomePageRecentlyVisited,
  HomePageStarredEntities,
  HomePageToolkit,
  HomePageTopVisited,
  TemplateBackstageLogo,
} from '@backstage/plugin-home';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { synColors } from '@internal/backstage-plugin-synechron-theme-react';
import { makeStyles } from '@material-ui/core';
import useMediaQuery from '@mui/material/useMediaQuery';
import { default as Grid } from '@mui/material/Grid2';
import { styled, useTheme } from '@mui/material/styles';
import React from 'react';
import { tools, useHomepageStyles } from './shared';

const { WHITE, BLACK } = synColors;

const useStyles = makeStyles(theme => ({
  searchBarInput: {
    maxWidth: '60vw',
    margin: 'auto',
    backgroundColor: WHITE,
    color: BLACK,
    borderRadius: '50px',
    boxShadow: theme.shadows[1],
    '& input::placeholder': {
      opacity: 1,
    },
  },
  searchBarOutline: {
    borderStyle: 'none',
  },
}));

const FixedHeightGrid = styled(Grid)({
  '& > div': {
    height: '100%',
  },
});

const columns = 3;

const HomePageTopSection = () => {
  const classes = useStyles();
  const { svg, path, container } = useHomepageStyles();

  return (
    <>
      <HomePageCompanyLogo
        className={container}
        logo={<TemplateBackstageLogo classes={{ svg, path }} />}
      />
      <HomePageSearchBar
        InputProps={{
          classes: {
            root: classes.searchBarInput,
            notchedOutline: classes.searchBarOutline,
          },
        }}
        placeholder="Search"
      />
    </>
  );
};

const HomepageItem = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();
  const cardsPerRow = useMediaQuery(theme.breakpoints.down('md')) ? 1 : columns;

  return <FixedHeightGrid size={12 / cardsPerRow}>{children}</FixedHeightGrid>;
};

export const HomePage = () => (
  <Page themeId="home">
    <Content>
      <Grid
        container
        spacing={2}
        direction="column"
        alignItems="center"
        width="100%"
      >
        <HomePageTopSection />
        <Grid container spacing={3} size={12} alignItems="stretch">
          <HomepageItem children={<HomePageTopVisited />} />
          <HomepageItem children={<HomePageRecentlyVisited />} />
          <HomepageItem children={<HomePageToolkit tools={tools} />} />
          <HomepageItem children={<HomePageStarredEntities />} />
        </Grid>
      </Grid>
    </Content>
  </Page>
);
