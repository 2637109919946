import { parseEntityRef } from '@backstage/catalog-model';
import { FavoriteToggle } from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';
import { catalogPlugin } from '@backstage/plugin-catalog';
import {
  EntityRefLink,
  useStarredEntities,
} from '@backstage/plugin-catalog-react';
import ButtonBase from '@mui/material/ButtonBase';
import { default as Grid } from '@mui/material/Grid2';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const entityRouteRef = catalogPlugin.routes.catalogEntity;

const EntityListItem = styled(Grid)(({ theme }) => ({
  justifyContent: 'space-between',
  padding: '8px 1rem',
  '&:hover': {
    backgroundColor: theme.palette.background.default,
  },
}));

const UnstyledLink = styled(Typography)({
  textWrap: 'nowrap',
  'a > span': {
    '&:nth-child(1)': {
      verticalAlign: 'middle',
      maxWidth: '20rem',
      display: 'inline-block',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',

      '& svg': {
        marginRight: '0.5rem',
      },
    },
  },
});

export const FavouriteEntityListItem = ({
  entityRef,
  handleClose,
}: {
  entityRef: string;
  handleClose: (force?: boolean) => void;
}) => {
  const navigate = useNavigate();
  const entityRoute = useRouteRef(entityRouteRef);
  const { isStarredEntity, toggleStarredEntity } = useStarredEntities();

  return (
    <ButtonBase>
      <EntityListItem
        spacing={2}
        wrap="nowrap"
        container
        size={12}
        onClick={event => {
          const { nodeName } = event.target as HTMLElement;

          if (['svg', 'path'].includes(nodeName)) return;
          handleClose();
          navigate(entityRoute(parseEntityRef(entityRef)));
        }}
      >
        <UnstyledLink
          sx={{
            'a > span': {
              textDecoration: 'none',
            },
          }}
        >
          <EntityRefLink entityRef={entityRef} />
        </UnstyledLink>
        <FavoriteToggle
          title=""
          id={`toggle-${entityRef}`}
          isFavorite={isStarredEntity(entityRef)}
          onToggle={() => toggleStarredEntity(entityRef)}
          style={{ padding: 0 }}
        />
      </EntityListItem>
    </ButtonBase>
  );
};
